import React, {Component} from 'react';

//import brand from '../../Layout/Assets/brand.png';
//import LogoTravel from '../../Layout/Assets/log-footer-1.jpg';
import LogoPedidosYa from '../../Layout/Assets/log-footer-2.jpg';
//import BancoRipley from '../../Layout/Assets/log-footer-3.jpg';
import { GetForCountry }from '../../Services/Helpers';
export default class Footer extends Component {
	render() {
		const {
			partner,
			facebook,
			instagram,
			allRightsReserved
		} = GetForCountry();
		const currentYear = new Date().getFullYear();
		return (
			<>
				<footer className="my-5 container">
					<div className="row">
						<div className="col-12 text-center pt-5 mt-5">
							{/*<img 
							alt="Icon"
							className="img-fluid header-logo"
							src={brand}
							scale="0" width="229"
							height="101"
							/>*/}
							<br/>
							<h5 className="mb-3">
								Redes sociales
							</h5>
							<a href={facebook}>
								<i className="fab fa-facebook fa-2x mr-2 text-muted" aria-hidden="true"/> 
							</a>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<a href={instagram} >
								<i className="fab fa-instagram fa-2x mr-2 text-muted" aria-hidden="true"/>
							</a>
						</div>
						{partner}
						<div className="col-6 text-left px-0 pb-1 pt-5">
							{allRightsReserved}
						</div>
						<div className="col-6 text-right px-0 pb-1 pt-5">
							© {currentYear} Powered By <a href="https://gux.tech/" target="_blank" rel="noopener noreferrer">gux.tech</a>
						</div>
					</div>
				</footer>
				<br/>
				<br/>
				<br/>
			</>
		);
	}
}